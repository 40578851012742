<template>
  <div>
    <div class="card-toolbar">
      <a @click.stop="dialog = true" class="btn btn-primary font-weight-bolder">
        <span class="svg-icon svg-icon-white"
          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Weather/Temperature-half.svg--><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M18,16 C18,19.3137085 15.3137085,22 12,22 C8.6862915,22 6,19.3137085 6,16 C6,13.7791529 7.20659589,11.8401214 9,10.8026932 L9,5 C9,3.34314575 10.3431458,2 12,2 C13.6568542,2 15,3.34314575 15,5 L15,10.8026932 C16.7934041,11.8401214 18,13.7791529 18,16 Z M12,4 C11.4477153,4 11,4.44771525 11,5 L11,10 C11,10.5522847 11.4477153,11 12,11 C12.5522847,11 13,10.5522847 13,10 L13,5 C13,4.44771525 12.5522847,4 12,4 Z"
                fill="#000000"
                fill-rule="nonzero"
              />
            </g></svg
          ><!--end::Svg Icon--></span
        >

        {{ $t('MENU.STAFF.ILNESS') }} +
      </a>
    </div>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="headline">Qoshish</v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="values"
            :items="allStaff"
            outlined
            item-text="full_name"
            item-value="id"
            dense
            label="Hodim"
          ></v-autocomplete>
          <v-dialog
            ref="dialog1"
            v-model="modal"
            :return-value.sync="from_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="from_date | dateFilter"
                label="Boshlanish sanasi"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="from_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog1.save(from_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog2"
            v-model="modal2"
            :return-value.sync="to_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tugash sanasi"
                append-icon="event"
                :value="to_date | dateFilter"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="to_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog2.save(to_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-text-field
            label="Izoh"
            v-model="comments"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="dialog = false"> Bekor qilish </v-btn>

          <v-btn color="success" @click="submit"> Yuborish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      values: '',
      modal: false,
      modal2: false,
      from_date: '',
      to_date: '',
      dialog: false,
      comments: ''
    }
  },
  created() {
    this.$store.dispatch('getAllStaff')
  },
  computed: {
    allStaff() {
      return this.$store.getters.allStaff
    }
  },
  methods: {
    submit() {
      const data = {
        from_date: this.from_date,
        to_date: this.to_date,
        comments: this.comments,
        employee: this.values
      }
      this.$store.dispatch('createStaffIllnesses', data)
      //   console.log(user_data,data);
      this.from_date = ''
      this.to_date = ''
      this.comments = ''
      this.employee = ''
      this.dialog = false
    }
  }
}
</script>

<style></style>

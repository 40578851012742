<template>
  <div>
    <div class="navi-link" @click.stop="dialog = true">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
    </div>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">{{ $t('ACTION.UPDATE') }}</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="name"
            :items="allStaff"
            label="Hodim"
            item-text="full_name"
            item-value="id"
            return-object
            dense
            outlined
          ></v-autocomplete>
          <v-dialog
            ref="dialog1"
            v-model="modal"
            :return-value.sync="from_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="from_date"
                label="Boshlanish sanasi"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="from_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog1.save(from_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog2"
            v-model="modal2"
            :return-value.sync="to_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="to_date"
                label="Tugash sanasi"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="to_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog2.save(to_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-text-field
            label="Izoh"
            v-model="getData.comments"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="dialog = false"> Bekor qilish </v-btn>

          <v-btn color="success" @click="submit"> Yuborish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      modal: false,
      modal1: false,
      modal2: false,
      from_date: '',
      to_date: '',
      employee: '',
      employedSelect: '',
      comments: '',
      for_period_from: '',
      for_period_to: '',
      name: '',
      data: ''
    }
  },

  created() {
    this.$store.dispatch('staffDepartments')
    this.$store.dispatch('getAllStaff')
    this.$store.dispatch('staffVacations')
    this.from_date = this.getData.from_date
    this.to_date = this.getData.to_date
    this.data = this.staffData
  },
  watch: {
    data: function (val) {
      this.name = val.employee
    }
  },
  computed: {
    getData() {
      return this.staffData
    },
    allStaff() {
      return this.$store.getters.allStaff
    }
  },
  methods: {
    submit() {
      const data = {
        from_date: this.from_date,
        to_date: this.to_date,
        comments: this.getData.comments,
        employee: this.name
      }
      this.$store.dispatch('illnessesUpdate', { id: this.staffData.id, data })
      console.log(data)
      this.dialog = false
    }
  },
  props: {
    staffData: Object
  }
}
</script>

<style></style>

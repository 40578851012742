<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.STAFF.ILNESS') }}
              </h3>
            </div>
            <div class="card-button">
              <create />
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :page.sync="page"
              no-data-text="Ma'lumotlar topilmadi"
              loading-text="Ma'lumotlar yuklanmoqda"
              :items-per-page="itemsPerPage"
              :items="getAllStaffIllnesses"
              hide-default-footer
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getAllStaffIllnesses
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div style="text-align: right !important">
                  <action :index="item"></action>
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="text-left my-5">
            <v-pagination
              v-model="currentPage"
              :total-visible="10"
              @input="getPostData(currentPage)"
              :length="Math.ceil(getCount.count / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './action'
import create from './create'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action, create },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Boshlangdan sana',
          value: 'from_date'
        },
        {
          text: 'Tugash sanasi',
          value: 'to_date'
        },
        {
          text: 'Izoh',
          value: 'comments'
        },

        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('staffDepartments')
    this.$store.dispatch('getRegions')
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
    this.$store.dispatch('getAllStaffIllnesses', this.index)
  },
  computed: {
    getCount() {
      return this.$store.state.requests.allStaffIllnesses
    },
    getAllStaffIllnesses() {
      return this.$store.getters.allStaffIllnesses.results
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      this.$store.dispatch('getAllStaffIllnesses', value)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.STAFF.TITLE') },
      { title: this.$t('MENU.STAFF.ILNESS') }
    ])
  }
}
</script>

<style></style>
